import { alpha } from '@theme-ui/color';
import { Field, FieldProps } from 'formik';
import colors from 'gatsby-plugin-theme-ui/colors';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { FaFilePdf, FaCheckCircle, FaFileUpload } from 'react-icons/fa';
import { Box, Input, Label, Text } from 'theme-ui';
import { FormField as FormFieldProps } from 'types/Forms.types';

import FormFieldError from './FormField.Error';
import FormFieldStatus from './FormField.Status';

function FormFieldFile({
  question,
  id,
  placeholder,
  required,
  fieldType,
}: FormFieldProps) {
  const [overActive, setOverActive] = useState<boolean>(false);
  return (
    <Field
      name={id}
      render={({
        field,
        form: { touched, errors, setFieldValue, setFieldTouched },
        meta: { value },
      }: FieldProps) => (
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            position: 'relative',
            mb: 2,
          }}
        >
          {/* {console.log({ value })} */}
          <Box sx={{ maxWidth: 300, width: '100%', mb: 3 }}>
            <Label htmlFor={id}>{question}</Label>
            {required && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 2,
                  top: 2,
                }}
              >
                <FormFieldStatus
                  id={id}
                  form={{ touched, errors }}
                  value={value}
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
            }}
          >
            <Dropzone
              accept={{
                'application/pdf': ['.pdf'],
              }}
              maxSize={8000000}
              multiple={false}
              onDrop={(acceptedFiles) => {
                setFieldValue(id, acceptedFiles[0]);
                if (!touched[id]) {
                  setFieldTouched(id, true);
                  setOverActive(false);
                }
              }}
              onDragEnter={() => setOverActive(true)}
              onDragLeave={() => setOverActive(false)}
            >
              {({ getRootProps, getInputProps, acceptedFiles }) => (
                <Box
                  sx={{
                    textAlign: 'center',
                    borderColor: 'gray',
                    borderWidth: 4,
                    borderStyle: 'dashed',
                    p: 3,
                    py: 5,
                    cursor: 'pointer',
                    transition: 'ease-in-out 200ms all',

                    '&:hover': {
                      backgroundColor: alpha(colors.gray, 0.4),
                    },
                  }}
                  {...getRootProps()}
                >
                  <Input {...getInputProps()} name={id} id={id} type="file" />
                  {acceptedFiles.length ? (
                    <>
                      <Text sx={{ wordBreak: 'break-all', maxWidth: '100%' }}>
                        <Box sx={{ mb: 1, color: 'success' }}>
                          <FaCheckCircle size={28} />
                        </Box>
                        <FaFilePdf size={16} /> {acceptedFiles[0].name}
                      </Text>
                    </>
                  ) : (
                    <Text>
                      {overActive ? (
                        <>
                          <Box sx={{ mb: 1, color: 'success' }}>
                            <FaCheckCircle size={28} />
                          </Box>
                          DROP IT!
                        </>
                      ) : (
                        <>
                          <Box sx={{ mb: 1 }}>
                            <FaFileUpload size={28} />
                          </Box>
                          {placeholder}
                        </>
                      )}
                    </Text>
                  )}
                </Box>
              )}
            </Dropzone>
            {required && (
              <>
                <Box sx={{ position: 'absolute', bottom: -28, right: 3 }}>
                  <FormFieldError
                    id={id}
                    form={{ touched, errors }}
                    value={value}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      )}
    />
  );
}

export default FormFieldFile;
