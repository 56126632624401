import { FieldProps } from 'formik';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { FaRegSmile, FaRegAngry, FaAsterisk } from 'react-icons/fa';
import { Box } from 'theme-ui';

function FormFieldStatus({
  id,
  form: { touched, errors },
  value,
}: {
  form: Partial<FieldProps['form']>;
  id: string;
  value: string;
}) {
  return (
    <Box>
      {touched?.[id] && !errors?.[id] && (
        <Fade>
          <FaRegSmile size={18} color="#39ff14" />
        </Fade>
      )}
      {touched?.[id] && errors?.[id] && (
        <Fade>
          <FaRegAngry size={18} color="#ff0000" />
        </Fade>
      )}
      {!touched?.[id] && !value && (
        <Fade>
          <Box sx={{ color: 'placeholder' }}>
            <FaAsterisk size={18} color="inherit" title="Required" />
          </Box>
        </Fade>
      )}
    </Box>
  );
}

export default FormFieldStatus;
