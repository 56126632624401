import React from 'react';
import { Box } from 'theme-ui';
import { FormField as FormFieldProps, FormFieldType } from 'types/Forms.types';

import FormFieldFile from './FormField.File';
import FormFieldText from './FormField.Text';
import FormFieldOption from './Formfield.Option';

const fieldComponents = {
  [FormFieldType.CHECKBOX]: FormFieldOption,
  [FormFieldType.FILE_UPLOAD]: FormFieldFile,
  [FormFieldType.RADIO]: FormFieldOption,
  [FormFieldType.SELECT]: FormFieldText,
  [FormFieldType.TEXT]: FormFieldText,
  [FormFieldType.TEXTAREA]: FormFieldText,
};

const qSpacing = [4, 4, 4, 4];

function FormField(field: FormFieldProps) {
  const El = fieldComponents[field.fieldType];
  return (
    <Box
      sx={{
        borderColor: 'placeholder',
        borderStyle: 'solid',
        borderWidth: '0 0 1px 0',
        pb: qSpacing,
        mb: qSpacing,
      }}
    >
      <El {...field} />
    </Box>
  );
}

export default FormField;
