import SEO from 'components/atoms/Seo';
import Form from 'components/organisms/Form';
import Layout from 'components/organisms/Layout';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { useEffect } from 'react';
import { FormInstance } from 'types/Forms.types';

const Careers = () => {
  const {
    allFormsYaml: { nodes: formData },
  } = useStaticQuery(graphql`
    query CareersFormQuery {
      allFormsYaml(filter: { formId: { eq: "CAREERS" } }) {
        nodes {
          id
          subTitle
          title
          formId
          successMessage
          questions {
            fieldType
            id
            placeholder
            question
            required
            requiredMessage
            options {
              name
              value
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (formData?.[0]?.formId !== FormInstance.CAREERS) {
      navigate('/404/');
    }
  }, [formData]);

  return (
    <Layout>
      <SEO />
      <Form {...formData[0]} />
    </Layout>
  );
};

export default Careers;
