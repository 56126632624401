import { keyframes } from '@emotion/react';
import React from 'react';
import { IconBaseProps } from 'react-icons';
import { FaSpinner } from 'react-icons/fa';
import { Box } from 'theme-ui';

const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' },
});

export default function Spinner(props: IconBaseProps) {
  return (
    <Box
      sx={{
        svg: { animation: `${spin} 1s infinite linear` },
        display: 'block',
      }}
    >
      <FaSpinner {...props} />
    </Box>
  );
}
