export enum FormInstance {
  CAREERS = 'CAREERS',
}

export enum FormFieldType {
  CHECKBOX = 'Checkbox',
  FILE_UPLOAD = 'FileUpload',
  RADIO = 'Radio',
  SELECT = 'Select',
  TEXT = 'Text',
  TEXTAREA = 'Textarea',
}

type FormFieldOption = {
  name: string;
  value: string;
};

export interface FormField {
  fieldType: FormFieldType;
  id: string;
  options?: FormFieldOption[];
  placeholder?: string;
  question: string;
  required: boolean;
  requiredMessage?: string;
}

export interface FormProps {
  formId: FormInstance;
  title: string;
  subTitle: string;
  questions: FormField[]
  successMessage: string;
}
