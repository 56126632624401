import { Field, FieldProps } from 'formik';
import React from 'react';
import { Box, Input, Label, Textarea } from 'theme-ui';
import { FormField as FormFieldProps, FormFieldType } from 'types/Forms.types';

import FormFieldError from './FormField.Error';
import FormFieldStatus from './FormField.Status';

function FormFieldText({
  question,
  id,
  placeholder,
  required,
  fieldType,
}: FormFieldProps) {
  const El = fieldType === FormFieldType.TEXTAREA ? Textarea : Input;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: ['wrap', 'wrap', 'nowrap', 'nowrap'],
      }}
    >
      <Box
        sx={{
          maxWidth: ['none', 'none', 300, 300],
          pt: 1,
          width: '100%',
          mb: [3, 3, 0, 0],
        }}
      >
        <Label htmlFor={id}>{question}</Label>
      </Box>
      <Field
        name={id}
        render={({
          field,
          form: { touched, errors },
          meta: { value },
        }: FieldProps) => (
          <Box sx={{ position: 'relative', width: '100%' }}>
            <El
              {...field}
              type="text"
              placeholder={placeholder}
              id={id}
              name={id}
              rows={4}
            />
            {required && (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: -24,
                    right: [2, 2, 3, 3],
                  }}
                >
                  <FormFieldError
                    id={id}
                    form={{ touched, errors }}
                    value={value}
                  />
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: [2, 2, 3, 3],
                    top: '50%',
                    transform: 'translateY(-50%)',
                  }}
                >
                  <FormFieldStatus
                    id={id}
                    form={{ touched, errors }}
                    value={value}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      />
    </Box>
  );
}

export default FormFieldText;
