import { FieldProps } from 'formik';
import React from 'react';
import { Fade, Slide } from 'react-awesome-reveal';
import { Text } from 'theme-ui';

function FormFieldError({
  id,
  form: { touched, errors },
  value,
}: {
  form: Partial<FieldProps['form']>;
  id: string;
  value: string;
}) {
  return (
    <>
      {touched?.[id] && errors?.[id] && (
        <Fade>
          <Text sx={{ fontSize: 2, color: 'error' }}>{errors?.[id]}</Text>
        </Fade>
      )}
    </>
  );
}

export default FormFieldError;
