import { Box } from '@theme-ui/components';
import Spinner from 'components/atoms/Spinner';
import FormField from 'components/molecules/FormField';
import { Formik, Form as FormikForm } from 'formik';
import React, { useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Button, Container, Flex, Heading } from 'theme-ui';
import { FormFieldType, FormProps } from 'types/Forms.types';
import * as Yup from 'yup';

const defaultMessage = 'this question requires an answer';
const validationByFieldType = {
  [FormFieldType.CHECKBOX]: (message: string) => Yup.string().required(message),
  [FormFieldType.FILE_UPLOAD]: (message: string) =>
    Yup.mixed().required(message),
  [FormFieldType.RADIO]: (message: string) => Yup.string().required(message),
  [FormFieldType.SELECT]: (message: string) => Yup.string().required(message),
  [FormFieldType.TEXT]: (message: string) => Yup.string().required(message),
  [FormFieldType.TEXTAREA]: (message: string) => Yup.string().required(message),
};

const encode = (data: { [key: string]: any }) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

function Form({
  formId,
  questions,
  subTitle,
  successMessage,
  title,
}: FormProps) {
  const formEl = useRef(null);
  const [done, setDone] = useState(false);
  const initialValues = questions.reduce(
    (all, curr) => ({ [curr.id]: '', ...all }),
    {}
  );

  const validationSchema = questions
    .filter(({ required }) => required)
    .reduce((all, curr) => {
      return {
        ...all,
        [curr.id]: validationByFieldType[curr.fieldType](
          curr.requiredMessage || defaultMessage
        ),
      };
    }, {});

  return (
    <Box>
      {!done && (
        <Container>
          <Flex
            sx={{
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <Fade>
              <Heading as="h1" sx={{ mb: 4 }}>
                {title}
              </Heading>
            </Fade>
            <Fade>
              <Heading as="h2" sx={{ variant: 'text.default', mb: 5 }}>
                {subTitle}
              </Heading>
            </Fade>
          </Flex>
        </Container>
      )}
      <Box sx={{ maxWidth: [800, 800, 800, 800], margin: '0 auto', px: 16 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting, submitForm }) => {
            setSubmitting(true);
            if (formEl.current) {
              const response = await fetch('/', {
                body: encode({
                  'form-name': formId,
                  ...values,
                }),
                method: 'POST',
              });

              if (response.ok) {
                setDone(true);
              }
            }
          }}
          validationSchema={Yup.object().shape(validationSchema)}
        >
          {({ isSubmitting, values }) => (
            <FormikForm
              data-netlify="true"
              name={formId}
              encType="multipart/form-data"
              // method="POST"
              // action="/"
              ref={formEl}
            >
              {done ? (
                <Fade>
                  <Box sx={{ textAlign: 'center', mt: [7, 7, 7, 7] }}>
                    <Heading sx={{ whiteSpace: 'pre' }}>
                      {successMessage.replace(/\\n/g, '\n')}
                    </Heading>
                  </Box>
                </Fade>
              ) : (
                <>
                  <input type="hidden" name="form-name" value={formId} />
                  {questions.map((q) => (
                    <FormField {...q} key={q.id} />
                  ))}

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      pt: 3,
                      pb: 7,
                    }}
                  >
                    <Button type="submit" sx={{ variant: 'buttons.submit' }}>
                      {isSubmitting ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{ mr: 2 }}>
                            <Spinner size={18} />
                          </Box>
                          Submitting
                        </Box>
                      ) : (
                        'Submit Profile'
                      )}
                    </Button>
                  </Box>
                </>
              )}
            </FormikForm>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default Form;
