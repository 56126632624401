import { Field, FieldProps } from 'formik';
import React, { useState } from 'react';
import { Box, Button, Input, Label } from 'theme-ui';
import { FormField as FormFieldProps, FormFieldType } from 'types/Forms.types';

import FormFieldError from './FormField.Error';
import FormFieldStatus from './FormField.Status';

function FormFieldOption({
  question,
  id,
  required,
  fieldType,
  options,
}: FormFieldProps) {
  const [state, setState] = useState<{ [value: string]: boolean }>(
    options?.reduce((all, curr) => ({ ...all, [curr.value]: false }), {}) || {}
  );
  return (
    <Field
      name={id}
      render={({
        field,
        form: { touched, errors, setFieldValue, setFieldTouched },
        meta: { value },
      }: FieldProps) => (
        <Box>
          <Box sx={{ mb: 3, position: 'relative' }}>
            <Label htmlFor={id}>{question}</Label>

            {required && (
              <>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 2,
                    top: 2,
                  }}
                >
                  <FormFieldStatus
                    id={id}
                    form={{ touched, errors }}
                    value={value}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexWrap: 'wrap',
              flexBasis: 'auto',

              button: {
                mr: [0, 0, 3, 3],
                mb: 3,
              },
            }}
          >
            {options?.map((option) => (
              <Button
                key={option.value}
                sx={{ variant: 'buttons.option' }}
                type="button"
                className={field.value.includes(option.value) ? 'checked' : ''}
                onClick={(event) => {
                  const newState = {
                    ...state,
                    [option.value]: !field.value.includes(option.value),
                  };
                  setState(newState);

                  if (!touched[id]) {
                    setFieldTouched(id, true);
                  }

                  setFieldValue(
                    id,
                    fieldType === FormFieldType.CHECKBOX
                      ? Object.entries(newState)
                          .filter(([key, value]) => value)
                          .map(([key]) => key)
                          .join(', ')
                      : field.value === option.value
                      ? ''
                      : option.value
                  );
                }}
              >
                {option.name}
              </Button>
            ))}
            <Input sx={{display: 'none'}} name={id} defaultValue={field.value} />
            {required && (
              <Box sx={{ position: 'absolute', bottom: -16, right: 3 }}>
                <FormFieldError
                  id={id}
                  form={{ touched, errors }}
                  value={value}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    />
  );
}

export default FormFieldOption;
